import { useState, createContext, useEffect } from 'react';
import authService from '~/services/authService';

export interface IHistoryRoutes {
  route: string;
  state: string;
}

export interface IPageContext {
  collapseMenu: boolean;
  setCollapseMenu: React.Dispatch<React.SetStateAction<boolean>>;
  fixed: boolean;
  setFixed: React.Dispatch<React.SetStateAction<boolean>>;
  focus: boolean;
  setFocus: React.Dispatch<React.SetStateAction<boolean>>;
  isLogged: boolean;
  setIsLogged: React.Dispatch<React.SetStateAction<boolean>>;
  menu: any[];
  histoyRoutes: IHistoryRoutes;
  setHistoyRoutes: React.Dispatch<React.SetStateAction<IHistoryRoutes>>;
}

const PageContext = createContext<IPageContext>({} as IPageContext);

const PageProvider: React.FC = ({ children }: any) => {
  const [collapseMenu, setCollapseMenu] = useState(false);
  const [fixed, setFixed] = useState(false);
  const [focus, setFocus] = useState(false);
  const [isLogged, setIsLogged] = useState(false);
  const [menu, setMenu] = useState([]);
  const [histoyRoutes, setHistoyRoutes] = useState({
    route: '',
    state: '',
  });

  useEffect(() => {
    const load = async () => {
      const resquest = await authService.showMenu();
      setMenu(resquest);
    };
    if (isLogged) {
      load();
    }
  }, [isLogged]);

  return (
    <PageContext.Provider
      value={{
        collapseMenu,
        setCollapseMenu,
        fixed,
        setFixed,
        focus,
        setFocus,
        isLogged,
        setIsLogged,
        menu,
        histoyRoutes,
        setHistoyRoutes,
      }}
    >
      {children}
    </PageContext.Provider>
  );
};

export default PageProvider;
export { PageContext };
