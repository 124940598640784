import React, { useEffect, useState } from 'react';
import { IconClose } from '~/assets/Icons';
import { Button, Input, Modal, Select } from '~/components';
import { CloseButton, Title } from './styles';
import sectorService from '~/services/sectorService';

interface IProps {
  onClickClose: () => void;
  onChange: (e: any) => void;
}

const ModalAddFilters: React.FC<IProps> = ({
  onClickClose,
  onChange,
}: IProps) => {
  const [church, setChurch] = useState('');
  const [currentSectorId, setCurrentSectorId] = useState<any>(undefined);
  const [loading, setLoading] = useState(true);
  const [listSector, setListSector] = useState<any>([]);
  const [currentSectorName, setCurrentSectorName] = useState<any>('');
  const erro = '';

  useEffect(() => {
    const load = async () => {
      setLoading(true);
      const response = await sectorService.listSector({ limit: 200, page: 1 });
      setListSector(response.result);
      setLoading(false);
    };
    load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Modal>
      <>
        <CloseButton onClick={() => onClickClose()}>
          <IconClose />
        </CloseButton>
        <Title>Filtrar</Title>
        <Input
          value={church}
          onChange={e => setChurch(e.target.value)}
          label="Igreja"
        />
        <Select
          label={loading ? 'Carregando...' : 'Setor'}
          erro={erro}
          lista={listSector}
          currentValue={currentSectorName}
          onClickList={item => {
            setCurrentSectorName(item.name);
            setCurrentSectorId(item.id);
          }}
        />
        <div style={{ height: '30px' }} />
        <Button
          onClick={() => {
            onChange({
              church,
              currentSectorId,
            });
            onClickClose();
          }}
        >
          Filtrar
        </Button>
        <div style={{ height: '10px' }} />
      </>
    </Modal>
  );
};

export default ModalAddFilters;
