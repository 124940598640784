import api from './api';

interface ICreateUser {
  name: string;
  cpf: string | number;
  phone: string | number;
  email: string;
  password: string;
  profileId: string | number;
}
interface IListUsers {
  page?: number;
  church?: string;
  sector?: string;
  user?: string;
}

interface IUpdatePassword {
  actualPassword: string;
  password: string;
}
class UserService {
  async listProfile(): Promise<any> {
    const response = await api.get(`/profiles`);

    return response.data;
  }

  async listUsers({ page }: IListUsers): Promise<any> {
    const response = await api.get(`/users`, {
      params: {
        page,
      },
    });

    return response.data;
  }

  async listUsersBs({ page, church, sector, user }: IListUsers): Promise<any> {
    const response = await api.get(`/bs/users`, {
      params: {
        page,
        church,
        sector,
        user,
      },
    });

    return response.data;
  }

  async createUser({
    name,
    cpf,
    password,
    phone,
    email,
    profileId,
  }: ICreateUser): Promise<any> {
    const response = await api.post(`/users`, {
      name,
      cpf,
      password,
      phone,
      email,
      profile_id: profileId,
    });

    return response.data;
  }

  async getUserById(id: number | string): Promise<any> {
    const response = await api.get(`/users/${id}`);

    return response.data;
  }

  async deleteUser(id: number | string): Promise<any> {
    const response = await api.delete(`/bs/users/${id}`);

    return response.data;
  }

  async restoreUser(id: number | string): Promise<any> {
    const response = await api.patch(`/bs/users/${id}/restore`);

    return response.data;
  }

  async updatePassword({
    password,
    actualPassword,
  }: IUpdatePassword): Promise<any> {
    const response = await api.patch(`/users/password/me`, {
      password,
      actualPassword,
    });

    return response.data;
  }
}

export default new UserService();
