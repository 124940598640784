import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  margin: 3%;
`;

export const Content = styled.div`
  padding: 3%;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.17);
  border-radius: 3px;
  width: 100%;
  height: fit-content;
`;

export const ColorInput = styled.span`
  padding: 0 10px;
  input {
    padding: 0 !important;
  }
`;

export const Text = styled.p`
  padding-top: 25px;
  font-size: 14px;
`;
