/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Input, InputSearch, Loading, Page } from '~/components';
import {
  generateDefaultInputValues,
  generateRequiredInputValues,
  isEmpty,
  validateForm,
} from '~/utils';
import ModalDefalut from '~/components/ModalDefault';
import frequencyService from '~/services/frequencyService';
import {
  Container,
  Content,
  ErroAlert,
  Select,
  TextArea,
  Title,
} from './styles';
import churchService from '~/services/churchService';
import BiblicalSchool from '~/services/BiblicalSchool';
import {
  formatDecimal,
  isFormatMoneyDecimal,
} from '~/utils/format/isFormatMoney';

interface IPropsInput {
  isValid: boolean;
  value: string;
  required: boolean;
  error: string;
}

type typeInput = {
  [key: string]: IPropsInput;
};

const Frequency: React.FC = () => {
  const state: string[] = [];
  const stateJustification: string[] = [];

  // const { type } = useParams<{ type: string }>();
  const history = useHistory();

  const stateSchema = {
    ...generateRequiredInputValues(['date', 'parson', 'offer', 'tithe']),
    ...generateDefaultInputValues(['observation', 'justificationDate']),
  };

  const [currentChurchName, setCurrentChurchName] = useState('');
  const [listChurch, setListChurch] = useState([]);
  const [inputChurch, setInputChurch] = useState('');
  const [inputState, setInputState] = useState<typeInput>(stateSchema);
  const [listClasses, setListClasses] = useState([]);
  const [isVisibleModalErro, setIsVisibleModalErro] = useState(false);
  const [isVisibleModalErroRequest, setIsVisibleModalErroRequest] =
    useState(false);
  const [loading, setLoading] = useState(true);
  const [parson, setParson] = useState<'' | boolean>('');
  const [quarter, setQuarter] = useState<string>('');
  const [leson, setLeson] = useState<string>('');
  const [quarterErro, setQuarterErro] = useState<string>('');
  const [lesonErro, setLesonErro] = useState<string>('');
  const [church, setChurch] = useState(0);
  const [erroParson, setErroParson] = useState('');

  useEffect(() => {
    const loadList = async () => {
      setLoading(true);
      const responseChurch = await churchService.listChurch({
        // limit: 200,
        page: 1,
      });
      setListChurch(responseChurch.result);
      setLoading(false);
    };
    loadList();
  }, []);

  const getChurch = (id: number | string) => {
    const loadClasses = async () => {
      try {
        setLoading(true);
        const response = await frequencyService.listMyClasses(id);
        setListClasses(response.classes);
        response.classes.forEach((item: any) => {
          state.push(item.id);
          stateJustification.push(`justification${item.id}`);
        });
        const stateSchema = {
          ...generateRequiredInputValues([...state, 'date', 'offer', 'tithe']),
          ...generateDefaultInputValues([
            'observation',
            'justificationDate',
            ...stateJustification,
          ]),
        };
        setInputState(stateSchema);
      } catch {
        setIsVisibleModalErroRequest(true);
      }
      setLoading(false);
    };
    loadClasses();
  };

  const handleInput = (value: string, inputName: string) => {
    let error = '';
    let isValid = true;

    if (inputState![inputName].required && value.length === 0) {
      error = 'campo obrigatorio';
      isValid = false;
    }

    setInputState((prevState: any) => ({
      ...prevState,
      [inputName]: {
        isValid,
        value,
        required: inputState[inputName].required,
        error,
      },
    }));
  };

  const isFormValid = () => {
    const inputsWithError = validateForm(inputState);
    let hasError = false;

    if (church === 0) {
      hasError = true;
    }
    Object.keys(inputState).forEach(inputValue => {
      if (inputState[inputValue].error) {
        hasError = true;
      }
    });

    return isEmpty(inputsWithError) && !hasError;
  };

  const validAll = async () => {
    Object.entries(inputState).forEach(allInput => {
      handleInput(allInput[1].value, allInput[0]);
    });
    const create = async () => {
      setLoading(true);
      const state: any = [];

      if (leson === '') {
        setLesonErro('Campo obrigatório!');
        return null;
      }

      if (quarter === '') {
        setQuarterErro('Campo obrigatório!');
        return null;
      }

      Object.entries(inputState).forEach(allInput => {
        if (allInput[0].includes('justification')) {
          return null;
        }
        if (
          allInput[0] !== 'observation' &&
          allInput[0] !== 'justificationDate' &&
          allInput[0] !== 'tithe' &&
          allInput[0] !== 'offer' &&
          allInput[0] !== 'date'
        ) {
          if (allInput[1].value === '0') {
            return state.push({
              id: allInput[0],
              attendance: allInput[1].value,
              justification: inputState[`justification${allInput[0]}`].value,
            });
          }
          state.push({ id: allInput[0], attendance: allInput[1].value });
        }
        return null;
      });
      if (isFormValid()) {
        if (parson !== '') {
          try {
            await BiblicalSchool.CreateFrequency(
              {
                date: new Date(`${inputState.date.value}T03:00:00`),
                pastor_present: parson,
                tithes: isFormatMoneyDecimal(inputState.tithe.value),
                offers: isFormatMoneyDecimal(inputState.offer.value),
                comments: inputState.observation.value,
                classes: state,
                lesson_number: leson,
                quarter_number: quarter,
                out_of_date_justification: inputState.justificationDate.value,
                is_out_of_date: !!inputState.justificationDate.value,
              },
              church,
            );
            history.push('/frequencias-ebd');
          } catch {
            setIsVisibleModalErro(true);
          }
        } else {
          setErroParson('campo obrigatorio');
        }
      } else {
        setIsVisibleModalErro(true);
      }
      return setLoading(false);
    };
    create();
  };

  if (loading) {
    return <Loading />;
  }

  if (isVisibleModalErroRequest) {
    return (
      <ModalDefalut
        success={false}
        onClick={() => history.push('/relatorios-ebd')}
      >
        <>
          Ops... algo deu errado!
          <br />
          Tente novamente mais tarde.
        </>
      </ModalDefalut>
    );
  }

  return (
    <>
      {isVisibleModalErro && (
        <ModalDefalut
          success={false}
          onClick={() => setIsVisibleModalErro(false)}
        >
          Ops... algo deu errado!
        </ModalDefalut>
      )}
      <Page>
        <Container>
          <Content>
            <Title>Cadastrar frequência</Title>
            {/* <SubTitle>Valentina 1</SubTitle> */}
            <span>
              <InputSearch
                label="Igreja"
                lista={listChurch}
                valueInput={inputChurch}
                value={inputChurch}
                currentValue={currentChurchName}
                onChange={e => setInputChurch(e.target.value)}
                onClickList={e => {
                  setCurrentChurchName(e.name);
                  setInputChurch(e.name);
                  setChurch(e.id);
                  getChurch(e.id);
                }}
              />
              <Input
                label="Data da EBD"
                value={inputState.date.value}
                error={inputState.date.error}
                onChange={e => handleInput(e.target.value, 'date')}
                type="date"
              />
            </span>
            <span>
              <b>Aula:</b>
            </span>
            <Select
              error={!!lesonErro}
              onChange={e => {
                setLeson(e.target.value);
              }}
            >
              <option value=""> </option>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5</option>
              <option value="6">6</option>
              <option value="7">7</option>
              <option value="8">8</option>
              <option value="9">9</option>
              <option value="10">10</option>
              <option value="11">11</option>
              <option value="12">12</option>
              <option value="13">13</option>
            </Select>
            <span>
              <b>Trimestre:</b>
            </span>
            <Select
              error={!!quarterErro}
              onChange={e => {
                setQuarter(e.target.value);
              }}
            >
              <option value=""> </option>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
            </Select>
            <span>
              <b>Pastor(Dirigente) da Congregação Presente?</b>
            </span>
            <Select
              error={!!erroParson}
              onChange={e => {
                if (e.target.value === '0') {
                  setParson(false);
                }
                if (e.target.value === '1') {
                  setParson(true);
                }
                if (e.target.value === '') {
                  setParson('');
                }
              }}
            >
              <option value=""> </option>
              <option value="1">Sim</option>
              <option value="0">Não</option>
            </Select>
            {erroParson && <ErroAlert>{erroParson}</ErroAlert>}
            {listClasses.map((item: any) => {
              return (
                <span key={item.id}>
                  <Input
                    type="number"
                    label={`Classe de ${item.name}:`}
                    placeholder="Quantidade de alunos"
                    error={inputState[item.id].error}
                    value={inputState[item.id].value}
                    onChange={e =>
                      handleInput(
                        e.target.value.replace(/[^\d]+/g, ''),
                        item.id,
                      )
                    }
                  />
                  {inputState[item.id].value === '0' && (
                    <Input
                      label={`Justificativa da ausência(${item.name}):`}
                      placeholder="Justifique a ausência da turma"
                      error={inputState[`justification${item.id}`].error}
                      value={inputState[`justification${item.id}`].value}
                      onChange={e =>
                        handleInput(e.target.value, `justification${item.id}`)
                      }
                    />
                  )}
                </span>
              );
            })}
            <Input
              label="Valor Total de Oferta"
              value={formatDecimal(inputState.offer.value)}
              error={inputState.offer.error}
              onChange={e => handleInput(e.target.value, 'offer')}
            />
            <Input
              label="Valor Total de Dízimo"
              value={formatDecimal(inputState.tithe.value)}
              error={inputState.tithe.error}
              onChange={e => handleInput(e.target.value, 'tithe')}
            />
            <span>
              <b>Observação:</b>
              <TextArea
                value={inputState.observation.value}
                onChange={e => handleInput(e.target.value, 'observation')}
              />
            </span>
            <span>
              <b>Justificativa da Data:</b>
              <TextArea
                value={inputState.justificationDate.value}
                onChange={e => handleInput(e.target.value, 'justificationDate')}
              />
            </span>
            <Button
              onClick={() => validAll()}
              disabled={!isFormValid() || loading}
            >
              Salvar
            </Button>
          </Content>
        </Container>
      </Page>
    </>
  );
};

export default Frequency;
