/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Page, TablePage, Loading, Input } from '~/components';
import { Content, Header, FilterButton } from './styles';
import BiblicalSchool from '~/services/BiblicalSchool';
import { IconFilter } from '~/assets/Icons';
import { usePageContext } from '~/hooks';
import { Title } from '~/components/PageHeader/styles';
import ModalAddFilters from '../components/modalAddFiltersReports';

const FrequencyNotRegistered: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(20);
  const [pages, setPages] = useState(0);
  const [dataSource, setDataSource] = useState<any>([]);
  const [isVisibleModalFilter, setIsVisibleModalFilter] = useState(false);
  const [input, setInput] = useState('');
  const [time, setTime] = useState<any>(null);
  const [initialDate, setInitialDate] = useState('2024-01-01');
  const [finalDate, setFinalDate] = useState('2024-12-31');
  const [currentSectorId, setCurrentSectorId] = useState<any>(undefined);
  const { setHistoyRoutes, histoyRoutes } = usePageContext();

  const loadClasses = async ({
    page,
    church,
  }: {
    page: number;
    church?: string;
  }) => {
    setLoading(true);
    const res = await BiblicalSchool.listChurchNotRegistred({
      limit,
      page,
      church,
      finalDate,
      initialDate,
      sector: currentSectorId,
    });
    setDataSource(res.result);
    setPage(res.info.page);
    setPages(res.info.pages);
    setLoading(false);
  };

  const columns = [
    {
      title: 'Igreja',
      dataIndex: 'name',
      key: 'church',
      render: (e: any) => e,
    },
  ];

  const doSearch = async (val: string) => {
    setHistoyRoutes({
      route: histoyRoutes.route,
      state: val,
    });
    setPage(1);
    loadClasses({ page: 1, church: val });
  };

  useEffect(() => {
    loadClasses({ page, church: input });
  }, [page, limit, initialDate, finalDate, currentSectorId]);

  return (
    <>
      {isVisibleModalFilter && (
        <ModalAddFilters
          onChange={e => {
            setInitialDate(e.initialDate);
            setCurrentSectorId(e.currentSectorId);
            setFinalDate(e.finalDate);
          }}
          onClickClose={() => setIsVisibleModalFilter(false)}
        />
      )}

      <Page>
        <Header>
          <Title>Igrejas Pendentes</Title>
          <div style={{ width: '30%' }}>
            <Input
              placeholder="Pesquisar"
              onChange={(e: any) => {
                setInput(e.target.value);
                if (time !== null) {
                  clearTimeout(time);
                }
                setTime(
                  setTimeout(() => {
                    doSearch(e.target.value);
                  }, 1000),
                );
              }}
            />
          </div>
          <FilterButton onClick={() => setIsVisibleModalFilter(true)}>
            <IconFilter />
          </FilterButton>
        </Header>
        <Content>
          {loading ? (
            <Loading />
          ) : (
            <TablePage
              columns={columns}
              dataSource={dataSource}
              pagination={{
                showSizeChanger: true,
                current: page,
                pageSize: limit,
                total: pages * limit,
                onChange: e => {
                  setPage(e);
                },
                onShowSizeChange: (page, size) => {
                  if (page !== size) setLimit(size);
                },
              }}
              rowKey="id"
            />
          )}
        </Content>
      </Page>
    </>
  );
};

export default FrequencyNotRegistered;
