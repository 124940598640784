import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Input, Loading, ModalDefault, Page, PageHeader } from '~/components';
import leadersService from '~/services/leadersService';
import { generateRequiredInputValues, isEmpty, validateForm } from '~/utils';
import { Container, Content, Text } from './styles';
import churchService from '~/services/churchService';

interface IPropsInput {
  isValid: boolean;
  value: string;
  required: boolean;
  error: string;
}

type typeInput = {
  [key: string]: IPropsInput;
};

const EditLeaders: React.FC = () => {
  const history = useHistory();
  const { type } = useParams<{ type: string }>();
  const [loading, setLoading] = useState(false);
  const [typePage, setTypePage] = useState(false);
  const [church, setChurch] = useState();
  const [isVisibleModalErro, setIsVisibleModalErro] = useState(false);
  const [isVisibleModalSucess, setIsVisibleModalSucess] = useState(false);

  const stateSchema = {
    ...generateRequiredInputValues(['name']),
  };

  const [inputValue, setInputValue] = useState<typeInput>(stateSchema);

  useEffect(() => {
    if (type === 'create') {
      setTypePage(true);
    } else {
      const loadLeaders = async () => {
        setLoading(true);
        const response = await leadersService.getLeaderById(type);
        setInputValue((prevState: any) => ({
          ...prevState,
          name: {
            ...prevState.name,
            value: response.name,
          },
        }));
        if (response.actualChurchLeader?.id) {
          const church = await churchService.getchurchById(
            response.actualChurchLeader.id,
          );
          setChurch(church.name);
        }
        setLoading(false);
      };

      loadLeaders();
    }
  }, [type]);

  const handleInput = async (value: string, inputName: string) => {
    let error = '';
    let isValid = true;

    if (inputValue[inputName].required && value.length === 0) {
      error = 'campo obrigatorio';
      isValid = false;
    }

    setInputValue(prevState => ({
      ...prevState,
      [inputName]: {
        isValid,
        value,
        required: inputValue[inputName].required,
        error,
      },
    }));
  };

  const isFormValid = () => {
    const inputsWithError = validateForm(inputValue);
    let hasError = false;

    Object.entries(inputValue).forEach(allInput => {
      handleInput(allInput[1].value, allInput[0]);
    });

    Object.keys(inputValue).forEach(inputName => {
      if (inputValue[inputName].error) {
        hasError = true;
      }
    });

    return isEmpty(inputsWithError) && !hasError;
  };

  const toSave = async () => {
    if (typePage) {
      if (isFormValid()) {
        try {
          await leadersService.createLeader({
            name: inputValue.name.value,
          });
          setIsVisibleModalSucess(true);
        } catch {
          setIsVisibleModalErro(true);
        }
      } else {
        setIsVisibleModalErro(true);
      }
    } else if (isFormValid()) {
      try {
        await leadersService.editLeaderById({
          id: type,
          name: inputValue.name.value,
        });
        setIsVisibleModalSucess(true);
      } catch {
        setIsVisibleModalErro(true);
      }
    } else {
      setIsVisibleModalErro(true);
    }
  };

  if (loading) {
    return (
      <Page>
        <Loading />
      </Page>
    );
  }

  return (
    <>
      {isVisibleModalErro && (
        <ModalDefault
          success={false}
          onClick={() => setIsVisibleModalErro(false)}
        >
          Não foi possível salvar!
        </ModalDefault>
      )}
      {isVisibleModalSucess && (
        <ModalDefault success onClick={() => history.push('/dirigentes')}>
          Cadastro finalizado com sucesso!
        </ModalDefault>
      )}
      <Page>
        <PageHeader
          title={typePage ? 'Cadastrar Dirigente' : 'Editar Dirigente'}
          button="Salvar"
          onClick={() => toSave()}
        />
        <Container>
          <Content>
            <Input
              label="Nome"
              requiredLabel
              value={inputValue.name.value}
              onChange={e => handleInput(e.target.value, 'name')}
              error={inputValue.name.error}
            />
            <Text>
              <b>Congregação:</b>{' '}
              {church || `Não estar vinculado a nenhuma congregação.`}
            </Text>
          </Content>
        </Container>
      </Page>
    </>
  );
};

export default EditLeaders;
