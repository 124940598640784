import React, { useEffect, useState } from 'react';
import { IconClose } from '~/assets/Icons';
import { Button, Input, InputSearch, Modal } from '~/components';
import leadersService from '~/services/leadersService';
import { CloseButton, Title } from './styles';

interface IProps {
  onClickClose: () => void;
  onClickSave: () => void;
  churchId: number;
}

const ModalAddLeader: React.FC<IProps> = ({
  onClickClose,
  onClickSave,
  churchId,
}: IProps) => {
  const [initialDate, setInitialDate] = useState('');
  const [listLeaders, setListLeaders] = useState<any>([]);
  const [currentLeadersName, setCurrentLeadersName] = useState('');
  const [inputLeaders, setInputLeaders] = useState('');
  const [leader, setLeader] = useState(0);

  useEffect(() => {
    const load = async () => {
      const responseLeaders = await leadersService.listOnlyLeaders({
        limit: 200,
        page: 1,
      });
      const responseLeadersTwo = await leadersService.listOnlyLeaders({
        limit: 200,
        page: 2,
      });
      console.log(responseLeaders.result.concat(responseLeadersTwo.result));
      setListLeaders(responseLeaders.result.concat(responseLeadersTwo.result));
    };
    load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const toSave = async () => {
    await leadersService.addLeaderInChurch({
      leaderId: leader,
      begined: initialDate,
      churchId,
    });
    onClickClose();
    onClickSave();
  };

  return (
    <Modal>
      <>
        <CloseButton onClick={() => onClickClose()}>
          <IconClose />
        </CloseButton>
        <Title>Editar dirigente da congregação</Title>
        <p>Dirigente:</p>
        <InputSearch
          label="Dirigente da congregação"
          lista={listLeaders}
          valueInput={inputLeaders}
          value={inputLeaders}
          currentValue={currentLeadersName}
          onChange={e => setInputLeaders(e.target.value)}
          onClickList={e => {
            setCurrentLeadersName(e.name);
            console.log(e.name);
            setInputLeaders(e.name);
            setLeader(e.id);
          }}
          requiredLabel
        />
        <Input
          type="date"
          onChange={e => setInitialDate(e.target.value)}
          label="Início"
        />

        <Button
          onClick={() => {
            toSave();
          }}
        >
          Salvar
        </Button>
      </>
    </Modal>
  );
};

export default ModalAddLeader;
