import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { IconDelete, IconEdit, IconFilter } from '~/assets/Icons';
import {
  Page,
  TablePage,
  Loading,
  ModalDelete,
  ModalDefault,
  Button,
  Input,
} from '~/components';
import userService from '~/services/userService';
import { BtEdit, BtTrash, Content } from './styles';
import { FilterButton, Header } from '../styles';
import { Title } from '~/components/PageHeader/styles';
import ModalAddFilters from '../components/modalAddFiltersUsers';

const UsersEBD: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState(0);
  const [limit] = useState(10);
  const [dataSource, setDataSource] = useState<any>([]);
  const [input, setInput] = useState('');
  const [time, setTime] = useState<any>(null);
  const [isVisibleModalDelete, setIsVisibleModalDelete] = useState(false);
  const [isVisibleModalErro, setIsVisibleModalErro] = useState(false);
  const [isVisibleModalFilter, setIsVisibleModalFilter] = useState(false);
  const [handleIdDelete, setHandleIdDelete] = useState(0);
  const [church, setChurch] = useState('');
  const [currentSectorId, setCurrentSectorId] = useState<any>(undefined);

  const history = useHistory();

  const loadUsers = async ({
    // limit,
    page,
  }: {
    limit: number;
    page: number;
  }) => {
    setLoading(true);

    if (page > 1) {
      const res = await userService.listUsersBs({
        page,
        church,
        sector: currentSectorId,
        user: input,
      });
      setDataSource((prevState: any) => [...prevState, ...res.result]);
      setPage(res.info.page);
    } else {
      const res = await userService.listUsersBs({
        page,
        church,
        sector: currentSectorId,
        user: input,
      });
      setDataSource(res.result);
      setPages(() => (input.length > 0 ? res.info.pages : res.info.pages + 1));
      setLoading(false);
    }
  };
  useEffect(() => {
    loadUsers({ limit, page });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, limit, church, currentSectorId]);

  const restoreUser = async (id: string | number) => {
    setLoading(true);
    try {
      await userService.restoreUser(id);
    } catch {
      setIsVisibleModalErro(true);
    }
    await loadUsers({ limit, page: 1 });
    setLoading(false);
  };

  const columns = [
    {
      title: 'Nome',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Função',
      dataIndex: 'profile',
      key: 'profile',
      render: (e: { name: string }) => e?.name,
    },
    {
      title: 'Igreja',
      dataIndex: 'church',
      key: 'church',
      render: (e: { name: string }) => e?.name,
    },
    {
      title: 'Ações',
      key: 'action',
      width: '100px',
      render: (e: any) => {
        if (e.deleted_at) {
          return <Button onClick={() => restoreUser(e.id)}>Restaurar</Button>;
        }
        return (
          <>
            <BtTrash
              onClick={() => {
                setHandleIdDelete(e.id);
                setIsVisibleModalDelete(true);
              }}
            >
              <IconDelete />
            </BtTrash>
            <BtEdit onClick={() => history.push(`/usuario-ebd/${e.id}`)}>
              <IconEdit />
            </BtEdit>
          </>
        );
      },
    },
  ];

  const deleteUser = async () => {
    setLoading(true);
    try {
      await userService.deleteUser(handleIdDelete);
    } catch {
      setIsVisibleModalErro(true);
    }
    setIsVisibleModalDelete(false);
    await loadUsers({ limit, page: 1 });
    setLoading(false);
  };

  const doSearch = async () => {
    setPage(1);
    loadUsers({ limit, page: 1 });
  };

  if (loading) {
    return (
      <Page>
        <Loading />
      </Page>
    );
  }

  return (
    <>
      {isVisibleModalFilter && (
        <ModalAddFilters
          onClickClose={() => setIsVisibleModalFilter(false)}
          onChange={e => {
            setChurch(e.church);
            setCurrentSectorId(e.currentSectorId);
            setPage(1);
          }}
        />
      )}
      {isVisibleModalDelete && (
        <ModalDelete
          text="usuário"
          onClickCancel={() => {
            setIsVisibleModalDelete(false);
            setHandleIdDelete(0);
          }}
          onClickDelete={() => deleteUser()}
        />
      )}
      {isVisibleModalErro && (
        <ModalDefault
          success={false}
          onClick={() => setIsVisibleModalErro(false)}
        >
          Ops algo deu errado
        </ModalDefault>
      )}

      <Page>
        <Header>
          <Title>Usuários EBD</Title>
          <div style={{ width: '30%' }}>
            <Input
              value={input}
              placeholder="Pesquisar"
              onChange={(e: any) => {
                setInput(e.target.value);
                if (time !== null) {
                  clearTimeout(time);
                }
                setTime(
                  setTimeout(() => {
                    doSearch();
                  }, 1000),
                );
              }}
            />
          </div>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Button onClick={() => history.push(`/usuario-ebd/create`)}>
              + Adicionar novo usuário EBD
            </Button>
            <FilterButton onClick={() => setIsVisibleModalFilter(true)}>
              <IconFilter />
            </FilterButton>
          </div>
        </Header>
        <Content>
          <TablePage
            columns={columns}
            dataSource={dataSource}
            pagination={{
              current: page,
              showSizeChanger: false,
              pageSize: limit,
              total: pages * limit,
              onChange: e => {
                setPage(e);
              },
            }}
            rowKey="id"
          />
        </Content>
      </Page>
    </>
  );
};

export default UsersEBD;
