import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';

import Routes from '~/routes';
import GlobalStyle from '~/styles/global';
import { defaultTheme } from '~/styles/Theme/styles';
import PageProvider from './context/PageContext';
import { store } from './store';
import FilterProvider from './context/FilterContext';

const App: React.FC = () => (
  <BrowserRouter>
    <Provider store={store}>
      <GlobalStyle />
      <PageProvider>
        <FilterProvider>
          <ThemeProvider theme={defaultTheme}>
            <Routes />
          </ThemeProvider>
        </FilterProvider>
      </PageProvider>
    </Provider>
  </BrowserRouter>
);

export default App;
