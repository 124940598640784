import { useHistory } from 'react-router-dom';
import { IoMdArrowRoundBack } from 'react-icons/io';
import { usePageContext } from '~/hooks/usePage';
import { Header } from '~/components';

import { Content, Container, BackButton } from './styles';

interface IProps {
  children: React.ReactNode;
  disabledBack?: boolean;
}

const defaultProps = {
  disabledBack: false,
};

const Page: React.FC<IProps> = ({ children, disabledBack }: IProps) => {
  const { fixed, setHistoyRoutes, histoyRoutes } = usePageContext();
  const history = useHistory();

  return (
    <Container>
      <Header />
      <Content fix={fixed}>
        <>
          {!disabledBack && (
            <BackButton
              onClick={() => {
                history.goBack();
                setHistoyRoutes({
                  route: history.location.pathname,
                  state: histoyRoutes.state,
                });
              }}
            >
              <IoMdArrowRoundBack /> Voltar
            </BackButton>
          )}
          {children}
        </>
      </Content>
    </Container>
  );
};

Page.defaultProps = defaultProps;

export default Page;
