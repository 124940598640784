import styled from 'styled-components';

export const Content = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  min-height: 100%;

  div {
    position: static;
  }
`;

export const Positive = styled.b`
  margin: 0 5px;
  color: #23ce6b;

  svg {
    margin: 0 5px;
  }
`;

export const Negative = styled.b`
  margin: 0 5px;
  color: ${({ theme }) => theme.Colors.Red};
  svg {
    margin: 0 5px;
  }
`;

export const Color = styled('div')<{ background: string }>`
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: ${props => props.background} !important;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: 90%;
  margin: 3%;
  div {
    width: 20%;
    margin-right: 15px;
    button,
    div {
      width: 100%;
    }
  }
  button {
    width: 30%;
  }
  @media (max-width: 768px) {
    flex-direction: column;
    justify-content: center;
    button {
      width: 95%;
    }
  }
`;

export const Actions = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;

  button {
    display: flex;
    align-items: center;
    margin-left: 5px;
  }
`;

export const ExcelButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 2px 9px rgba(0, 0, 0, 0.25);
  padding: 10px;
  height: 45px;
  width: 45px !important;
  background-color: #19754c;
  border-radius: 4px;
  margin-left: 10px;
`;

export const FilterButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 2px 9px rgba(0, 0, 0, 0.25);
  padding: 10px;
  height: 45px;
  width: 45px !important;
  background-color: #fff;
  border-radius: 4px;
  margin-left: 10px;
`;
