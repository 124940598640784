import { useContext } from 'react';
import { IFilterContext, FilterContext } from '~/context/FilterContext';

export function useFilterContext(): IFilterContext {
  const context = useContext(FilterContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  return context;
}
