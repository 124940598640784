import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Checkbox } from 'antd';

import {
  Input,
  InputSearch,
  Loading,
  // Loading,
  ModalDefault,
  Page,
  PageHeader,
} from '~/components';
import BiblicalSchool from '~/services/BiblicalSchool';
import churchService from '~/services/churchService';
import userService from '~/services/userService';
import { generateRequiredInputValues, isEmpty, validateForm } from '~/utils';
import isFormatCPF from '~/utils/format/isFormatCPF';
import isFormatPhone from '~/utils/format/isFormatPhone';
import isValidCPF from '~/utils/validate/isValidCPF';
import isValidEmail from '~/utils/validate/isValidEmail';
import { Container, ContainerBox, Content } from './styles';

interface IPropsInput {
  isValid: boolean;
  value: string;
  required: boolean;
  error: string;
}

type typeInput = {
  [key: string]: IPropsInput;
};

const EditUser: React.FC = () => {
  const history = useHistory();
  const { type } = useParams<{ type: string }>();
  const [loading, setLoading] = useState(false);
  const [menssageErro, setMenssageErro] = useState('');
  const [typePage, setTypePage] = useState(false);
  const [listChurch, setListChurch] = useState([]);
  const [church, setChurch] = useState({ id: 0, name: '' });
  const [churchValue, setChurchValue] = useState('');
  const [isVisibleModalErro, setIsVisibleModalErro] = useState(false);
  const [isVisibleModalSucess, setIsVisibleModalSucess] = useState(false);

  const stateSchema = {
    ...generateRequiredInputValues([
      'name',
      'cpf',
      'phone',
      'email',
      'password',
      'churchId',
    ]),
  };

  const [inputValue, setInputValue] = useState<typeInput>(stateSchema);
  const [profile, setProfile] = useState(0);

  useEffect(() => {
    if (type === 'create') {
      setTypePage(true);
    } else {
      const loadSector = async () => {
        setLoading(true);
        const response = await userService.getUserById(type);
        setInputValue((prevState: any) => ({
          ...prevState,
          name: {
            ...prevState.name,
            value: response.name,
          },
          cpf: {
            ...prevState.cpf,
            value: response.cpf,
          },
          phone: {
            ...prevState.phone,
            value: isFormatPhone(response.phone),
          },
          email: {
            ...prevState.email,
            value: response.email,
          },
          password: {
            ...prevState.password,
            value: '******',
          },
          churchId: {
            ...prevState.churchId,
            value: response.church_id,
          },
        }));
        setProfile(response.profile_id);
        const responseChurch = await churchService.getchurchById(
          response.church_id,
        );
        setChurch({ id: response.church_id, name: responseChurch.name });
        setChurchValue(responseChurch.name);
        setLoading(false);
      };
      loadSector();
    }
  }, [type]);

  useEffect(() => {
    const loadChurch = async () => {
      const response = await churchService.listChurch({ page: 1 });
      setListChurch(response.result);
    };

    loadChurch();
  }, []);

  const handleInput = async (value: string, inputName: string) => {
    let error = '';
    let isValid = true;

    if (inputName === 'email' && !isValidEmail(value)) {
      error = 'e-mail inválido';
      isValid = false;
    }

    if (inputName === 'cpf' && !isValidCPF(value)) {
      error = 'cpf inválido';
      isValid = false;
    }

    if (inputName === 'phone' && value.length < 13) {
      error = 'campo insuficiente';
      isValid = false;
    }

    if (inputName === 'password' && value.length < 6) {
      error = 'digite no mínimo 6 caracteres';
      isValid = false;
    }

    if (inputName === 'name' && value.length < 10) {
      error = 'digite no mínimo 10 caracteres';
      isValid = false;
    }

    setInputValue(prevState => ({
      ...prevState,
      [inputName]: {
        isValid,
        value,
        required: inputValue[inputName].required,
        error,
      },
    }));
  };

  const isFormValid = () => {
    const inputsWithError = validateForm(inputValue);
    let hasError = false;

    Object.entries(inputValue).forEach(allInput => {
      handleInput(allInput[1].value, allInput[0]);
    });

    Object.keys(inputValue).forEach(inputName => {
      if (inputValue[inputName].error) {
        hasError = true;
      }
    });
    return isEmpty(inputsWithError) && !hasError;
  };

  const toSave = async () => {
    if (typePage) {
      if (isFormValid()) {
        try {
          await BiblicalSchool.createUserBS({
            name: inputValue.name.value,
            cpf: inputValue.cpf.value.replace(/\D/g, ''),
            password: inputValue.password.value,
            phone: inputValue.phone.value.replace(/\D/g, ''),
            email: inputValue.email.value,
            churchId: inputValue.churchId.value,
            profileId: profile === 22 ? 22 : undefined,
          });

          setIsVisibleModalSucess(true);
        } catch (e: any) {
          setIsVisibleModalErro(true);
          const erro = JSON.parse(e.request.response);
          setMenssageErro(`(${erro.message})`);
        }
      }
    } else if (isFormValid()) {
      try {
        await BiblicalSchool.editUserBS({
          id: type,
          name: inputValue.name.value,
          email: inputValue.email.value,
          password: inputValue.password.value,
          phone: inputValue.phone.value.replace(/\D/g, ''),
          churchId: inputValue.churchId.value,
          cpf: inputValue.cpf.value.replace(/\D/g, ''),
          profileId: profile === 22 ? 22 : 19,
        });
        setIsVisibleModalSucess(true);
      } catch (e: any) {
        setIsVisibleModalErro(true);
        const erro = JSON.parse(e.request.response);
        setMenssageErro(`(${erro.message})`);
      }
    }
  };

  if (loading) {
    return (
      <Page>
        <Loading />
      </Page>
    );
  }

  return (
    <>
      {isVisibleModalErro && (
        <ModalDefault
          success={false}
          onClick={() => setIsVisibleModalErro(false)}
        >
          Não foi possível salvar! {menssageErro}
        </ModalDefault>
      )}
      {isVisibleModalSucess && (
        <ModalDefault success onClick={() => history.push('/usuarios-ebd')}>
          Cadastro finalizado com sucesso!
        </ModalDefault>
      )}
      <Page>
        <PageHeader
          title={typePage ? 'Criar Usuário' : 'Editar Usuário'}
          button="Salvar"
          onClick={() => toSave()}
        />
        <Container>
          <Content>
            <ContainerBox>
              <div>
                <b>Secretaria</b>
                <Checkbox
                  style={{ marginLeft: '10px' }}
                  checked={profile === 19}
                  onChange={e => setProfile(e.target.checked ? 19 : 22)}
                />
              </div>
              <div>
                <b>Dirigente</b>
                <Checkbox
                  style={{ marginLeft: '10px' }}
                  checked={profile === 22}
                  onChange={e => setProfile(e.target.checked ? 22 : 19)}
                />
              </div>
            </ContainerBox>
            <div style={{ height: '40px' }} />
            <ContainerBox>
              <Input
                label="Nome"
                requiredLabel
                value={inputValue.name.value}
                onChange={e => handleInput(e.target.value, 'name')}
                error={inputValue.name.error}
              />

              <Input
                label="CPF"
                requiredLabel
                maxLenght={14}
                value={isFormatCPF(inputValue.cpf.value)}
                onChange={e => handleInput(e.target.value, 'cpf')}
                error={inputValue.cpf.error}
              />

              <Input
                label="E-mail"
                type="email"
                requiredLabel
                value={inputValue.email.value}
                onChange={e => handleInput(e.target.value, 'email')}
                error={inputValue.email.error}
              />

              <Input
                label="Senha"
                type="password"
                requiredLabel
                value={inputValue.password.value}
                onChange={e => handleInput(e.target.value, 'password')}
                error={inputValue.password.error}
              />

              <Input
                label="Telefone"
                requiredLabel
                maxLenght={15}
                value={isFormatPhone(inputValue.phone.value)}
                onChange={e =>
                  handleInput(isFormatPhone(e.target.value), 'phone')
                }
                error={inputValue.phone.error}
              />
              <div>
                <InputSearch
                  label="Igreja"
                  onClickList={item => {
                    setChurch(item);
                    setChurchValue(item.name);
                    handleInput(item.id, 'churchId');
                  }}
                  currentValue={church.name}
                  lista={listChurch}
                  valueInput={churchValue}
                  error={inputValue.churchId.error}
                  onChange={e => setChurchValue(e.target.value)}
                />
              </div>
            </ContainerBox>
          </Content>
        </Container>
      </Page>
    </>
  );
};

export default EditUser;
