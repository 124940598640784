import { useState, createContext } from 'react';

export interface IHistoryRoutes {
  route: string;
  state: string;
}

export interface IFilterContext {
  inputSearch: string;
  setInputSearch: React.Dispatch<React.SetStateAction<string>>;
  filterSearch: any;
  setFilterSearch: React.Dispatch<React.SetStateAction<any>>;
}

const FilterContext = createContext<IFilterContext>({} as IFilterContext);

const FilterProvider: React.FC = ({ children }: any) => {
  const [inputSearch, setInputSearch] = useState('');
  const [filterSearch, setFilterSearch] = useState<any>({});

  return (
    <FilterContext.Provider
      value={{
        inputSearch,
        setInputSearch,
        filterSearch,
        setFilterSearch,
      }}
    >
      {children}
    </FilterContext.Provider>
  );
};

export default FilterProvider;
export { FilterContext };
